<template>

  <!-- Draft Index View -->
  <j-page
    title="Add Article"
    :loading="loading"
    fill
    id="draft-index-view"
  >

    <!-- Left - Source + Form -->
    <template #left>

      <!-- Router View -->
      <router-view />

    </template>

    <!-- Right - Preview -->
    <template #right>
      <j-card title="Preview">

        <!-- Article Preview -->
        <ArticlePreview
          v-if="draft.hasSource"
          :article="draft.articlePreview"
          :section="draftSection"
        />

        <!-- Select a source -->
        <j-card-text v-else>
          <j-alert message="Select a source to continue..." class="mt-4 mb-0" />
        </j-card-text>

      </j-card>
    </template>
  </j-page>
</template>

<script>

// Components
import ArticlePreview from '@/components/widgets/articles/ArticlePreview'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters } = createNamespacedHelpers('drafts')

export default {
  name: 'DraftIndexView',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticlePreview
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'New Draft'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'draft'
    ]),
    ...mapGetters([
      'draftSection',
      'loading'
    ])
  }
}
</script>

<style lang="scss">

.source-header {
  @extend %j-heading;
}

.assignment-title,
.section-name {
  @extend %j-headline;
}

.assignment-section {
  @extend %j-kicker;
}

.section-description {
  @extend %j-body-small;
}

</style>
